import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import {getPrincipalMessage} from'../Service/Api'
import NextArrow from'../Pages/NextArrow'
import PrevArrow from'../Pages/PrevArrow'
const Message = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); 
    console.log(data);
 
    useEffect(() => {
        const fetchData = async () => {
            try {
                const principalData = await getPrincipalMessage();
                setData(principalData);
                
            } catch (error) {
                console.log("Data Error", error);
            } finally{
                setLoading(false)
            }
        };
        fetchData();
    }, []);
    const emptyData = [
       { id: 1, category: "Principal's", message: "I am happy and delighted to address you all through school online platform. Education at St. Basil School is designed to meet the needs of each individual student. It is our foremost priority to bring the best of your children, but we are not enough. You, the parents, must play a responsible key role in shaping children. children are always the ultimate winner in the school campus. to make the world a better place to live." , name: "Name", images: "https://webapi.entab.info/api/image/SBSB/public/Images/principal.jpg" }
   ];
    const settings = {
        autoplay: true,
        infinite: true, 
        autoplaySpeed: 2000,
        speed: 500,
        slidesToShow:1,
        slidesToScroll: 1,
        initialSlide: 0,
        arrows: false, 
        adaptiveHeight: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
          {
            breakpoint: 1300,
            settings: {
              slidesToShow: 1,  
            }
          },
          {
            breakpoint: 992,
            settings: {
              slidesToShow:1, 
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1, 
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1, 
            }
          }
        ]
      };
  return (
     <>
     <Slider {...settings}> 
     {data && data.length > 0 ? (data.map((item, index) => ( 
        <div className="item" key={index}>  
            <div className="messagebox"> 
                 <div className="messagetop">
                        <div className="messageboxtitle">
                            <h3>{item.category}'s Message </h3>
                        </div>
                </div>
                <div className="principalimgbox">
                    <img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid"/>
                 </div>
                <div className="messageboximg">
                    <p> {item.name} <span> St. Basil’s School, Basti  <br/>{item.category}</span></p>
                    <img src="https://webapi.entab.info/api/image/SBSB/public/Images/quote.gif" className="img-fluid"/>
                </div>
                <div className="messagedesc">
                       <p> <div dangerouslySetInnerHTML={{ __html: item.message }} /></p>
                        </div>
                        <div className="mainbtn"><Link to="/PrincipalMessage"> View All</Link></div>
           </div>
        </div> 
        ))) : (emptyData.map((data, index) => (
            <div className="item" key={index}>  
            <div className="messagebox"> 
                 <div className="messagetop">
                        <div className="messageboxtitle">
                            <h3>{data.category} Message </h3>
                        </div>
                </div>
                <div className="principalimgbox">
                    <img src="https://webapi.entab.info/api/image/SBSB/public/Images/principal.jpg" className="img-fluid"/>
                 </div>
                <div className="messageboximg">
                    <p> {data.name} <span> St. Basil’s School, Basti  <br/>{data.category}</span></p>
                    <img src="https://webapi.entab.info/api/image/SBSB/public/Images/quote.gif" className="img-fluid"/>
                </div>
                <div className="messagedesc">
                         <p> {data.message}</p>
                        </div>
                        <div className="mainbtn"><Link to="/PrincipalMessage"> View All</Link></div>
           </div>
        </div> 

            )))}
     </Slider> 
     </>
  )
}

export default Message
