import React, { useEffect, useState } from "react";
import { getTopper } from "../Service/Api";
import Header from '../Component/Header'
import Footer from'../Component/Footer'
import { Link } from 'react-router-dom'
const TopperICS = () => {
   const [data, setData] = useState([]);
   const [filterData, setFilteredData] = useState([]);
   const [selectedYear, setSelectedYear] = useState("");
   const [yearRanges, setYearRanges] = useState([]);
   const [searchQuery, setSearchQuery] = useState("");
 
   // Initialize the selected year to the current session year
   useEffect(() => {
     const currentYear = new Date().getFullYear();
     const Year = `${currentYear - 1}-${currentYear}`;
     setSelectedYear(Year);
   }, []);
 
   // Fetch data and initialize year ranges
   useEffect(() => {
     const fetchData = async () => {
       const galleryData1 = await getTopper();
       const galleryData = galleryData1.filter((item) => item.class === 'XII');
       setData(galleryData);
 
       // Extract unique session years
       const uniqueYears = Array.from(
         new Set(galleryData.map((item) => item.sessionYear))
       ).sort((a, b) => b.localeCompare(a)); // Sort years in descending order
 
       setYearRanges(uniqueYears);
 
       // Check if default year has data; if not, fallback to the last available year
       const currentYear = new Date().getFullYear();
       const defaultYear = `${currentYear - 1}-${currentYear}`;
       const hasDefaultYearData = galleryData.some(
         (item) => item.sessionYear === defaultYear
       );
 
       if (!hasDefaultYearData && uniqueYears.length > 0) {
         setSelectedYear(uniqueYears[0]); // Set the most recent year as active
       }
     };
 
     fetchData();
   }, []);
 
   // Filter data based on the selected year and search query
   useEffect(() => {
     let filteredData = data;
 
     // Filter by selected year
     if (selectedYear !== "All") {
       filteredData = filteredData.filter((item) => item.sessionYear === selectedYear);
     }
 
     // Filter by search query
     if (searchQuery) {
       filteredData = filteredData.filter((item) =>
         item.name.toLowerCase().includes(searchQuery.toLowerCase())
       );
     }
 
     // Sort data by division in descending order
     filteredData.sort((a, b) => parseFloat(b.division) - parseFloat(a.division));
 
     setFilteredData(filteredData);
   }, [selectedYear, searchQuery, data]);
  const emptyArray = [
   { name: "Student Name", division: "%",stream:"Stream" },
   { name: "Student Name", division: "%",stream:"Stream" },
   { name: "Student Name", division: "%",stream:"Stream" },
   { name: "Student Name", division: "%",stream:"Stream" }
 ];
  return (
     <>
       <Header/>
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>
            <li> Topper ICS  </li>
        </ul>
    </div>
    <div className="innersec">
    <div className="container">
            <div className="row">
                <div className="col-lg-6">
                    <h1>Topper ICS </h1>
                </div>
                <div className="col-lg-6">
                <div className="chooseyear">
                <select id="chooseddlYear"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(e.target.value)}
                    className="yearSelection"
                >
                    <option value={"All"}>All</option>
                    {yearRanges.map((yr) => (
                        <option key={yr} value={yr}>{yr}</option>
                    ))}
                </select>
   
            </div>
              </div>
            </div>
        <div className="row">
        {filterData && filterData.length > 0  ? (filterData.map((item, index) => (
               <div className="col-lg-3">
                <div className="topperdiv" key={index}>
                  <div className="topperimages">
                    <img src={`https://webapi.entab.info/api/image/${item.attachments}`} className="img-fluid"/>
                  </div>
                  <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                  <div className="topperdesc">
                     <p className="per"> {item.division}</p>
                     <p className="name">{item.name}   <span> {item.stream} </span></p>
                  </div>
               </div>
            </div>  
            ))):(emptyArray.map((item, index) => ((
              <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={index}>
              <div class="topperdiv">
                      <div class="topperimages">
                          <img src="https://webapi.entab.info/api/image/SBSB/public/Images/defaultImage.jpg" class="img-fluid"/>
                      </div>
                      <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" class="img-fluid tooperpicicn"/>
                      <div class="topperdesc">
                          <p class="per">{item.division}</p>
                          <p class="name"> {item.name} <span> {item?.stream} </span></p>
                      </div>
                  </div>
           </div>
              ))))}  
          
        </div>
    </div>
    </div>
       <Footer/>
     </>
  )
}

export default TopperICS
