import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const AboutUs = () => {

  return (
     <>
       <Header/>
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>
            <li> About Us </li> 
        </ul>
    </div>
    <div className="innersec">
    <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>About Us </h1>
                </div>
            </div>
          <div className="row">
               <div className="col-lg-12">
                  <h5>About Us</h5>
                  <p><b>St. Basil's School, Basti, U.P.</b> is an English Medium Institution, established in the Catholic Diocese of Gorakhpur, and administered by the Little Flower Mission Education Society, a registered charitable organization. The school was started on <b>02 January 1984</b>, on the feast of St. Basil, the Patron Saint of Very Reverend Father Basilius CST, the Founder of Little Flower Congregation, the Parent society of the institution. </p>

                  <p>The school is situated in the calm and serene atmosphere at Basil Nagar, village Khirighat, on the outskirts of city. St Basil’s School is affiliated to the Council for the Indian School Certificate Examinations, New Delhi. It prepares the students for the ICSE Examination at the end of class X, and ISC Examination at the end of class XII. </p>
                  
                  <p>Being a minority institution, the school has the primary objective of educating the children of the community. However, we shall not spare any effort in rendering our service to all people, irrespective of caste and creed, so as to conserve their religion, language and culture. The school is established on the principle of equality and universal brotherhood. </p>
               </div>
          </div>
    </div>
    </div>
       <Footer/>
     </>
  )
}

export default AboutUs
