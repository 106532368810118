import React from 'react'
import Header from '../Component/Header'
import { Link } from 'react-router-dom'
import Footer from'../Component/Footer'
const CoCurricular = () => {
  return (
     <>
       <Header/>
        <div className="innerslide">
        <ul className="breadcrumb">
            <li><Link to="/"> Home</Link> </li>
          
            <li> Co-Curricular </li>
        </ul>
    </div>
    <div className="innersec">
    <div className="container">
            <div className="row">
                <div className="col-lg-12">
                    <h1>Co-Curricular </h1>
                </div>
            </div>
          <div className="row">
               <div className="col-lg-12">
                  <h5>Co-Curricular  Activities</h5>
                  <p><strong>1. </strong> All boys and girls are expected to take part in games. Sports and other school activities. Boys and girls must take part in games in pure team spirit and sportsman spirit.</p> 
                  <p><strong>2. </strong> Participation in Inter-House activities and school functions is obligatory, failure to participate may affect the performance report.</p> 
                  <p><strong>3. </strong> Students shall compulsorily take part in the SUPW programmes.</p>
                  </div> 
          </div>
    </div>
    </div>
       <Footer/>
     </>
  )
}

export default CoCurricular
