import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import { getTopper } from '../Service/Api';
const TopperXII = () => {
    const [classXIIData, setClassXIIData] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const topperData = await getTopper(); 
        
        const classXII = topperData.filter((item) => item.class === 'XII');
        setClassXIIData(classXII); 
         
      };
      fetchData();
    }, []);
  
    const emptyArray = [
      { name: "Student Name", division: "%",stream: 'Stream' },
      { name: "Student Name", division: "%",stream: 'Stream' },
      { name: "Student Name", division: "%",stream: 'Stream' },
      { name: "Student Name", division: "%",stream: 'Stream' }
    ];
  
    const settings = {
      autoplay: true,
      infinite: true, 
      autoplaySpeed: 2000,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false, 
      adaptiveHeight: true,
      responsive: [
        {
          breakpoint: 1300,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
  return (
     <> 
     <div className="row">
     {classXIIData.length > 0 ? (
        <Slider {...settings}> 
          {classXIIData.map((item1, index) => (
            <div className="item" key={index}>
              <div className="topperdiv">
                <div className="topperimages">
                    <img src={`https://webapi.entab.info/api/image/${item1?.attachments}`} className="img-fluid"/>
                    </div>
                    <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                    <div className="topperdesc">
                    <p className="per"> {item1?.division}</p>
                    <p className="name">{item1?.name}	  <span> {item1?.stream}  </span></p>
                    </div>
                </div>
           </div>
           ))}
           </Slider>
           ) : (
             <Slider {...settings}> 
               {emptyArray.map((data, index) => (
                 <div className="item" key={index}>
                    <div className="topperdiv">
                    <div className="topperimages">
                        <img src="https://webapi.entab.info/api/image/SBSB/public/Images/defaultImage.jpg" className="img-fluid"/>
                        </div>
                        <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                        <div className="topperdesc">
                    <p className="per"> {data.division}</p>
                    <p className="name">{data.name}	  <span> {data.stream}  </span></p>
                    </div>
                    </div>
                    </div>
                    ))}
                    </Slider>
                  )}
                </div>
                {/* 
                <div className="item">
                         <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/MOHAMMAD-AZEEM.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per"> 97.50%</p>
                             <p className="name">MOHAMMAD AZEEM    <span> Biology </span></p>
                             </div>
                         </div>
                         </div>
                         <div className="item">
                             <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/RIYA-SRIVASTAVA.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per">94.75%</p>
                             <p className="name">RIYA SRIVASTAVA <span> Biology </span></p>
                             </div>
                         </div>
                         </div>
                         <div className="item">
                         <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/RITIKA-GUPTA.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per">94.25%</p>
                             <p className="name">RITIKA GUPTA<span> Biology </span></p>
                             </div>
                         </div>
                         </div>
                         <div className="item">
                         <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/PATRICK-STEVE-HARRISON.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per"> 97.25%</p>
                             <p className="name">PATRICK STEVE HARRISON   <span> Maths </span></p>
                             </div>
                         </div>
                         </div>
                         <div className="item">
                             <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/ASMIT-SRIVASTAV.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per">94.25%</p>
                             <p className="name">ASMIT SRIVASTAV   <span> Maths </span></p>
                             </div>
                         </div>
                         </div>
                         <div className="item">
                         <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/AKHIL-JOBBI.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per">94.25%</p>
                             <p className="name">AKHIL JOBBI <span> Maths </span></p>
                             </div>
                         </div>
                         </div>
                         <div className="item">
                         <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/RICHA-GUPTA.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per"> 93% </p>
                             <p className="name">RICHA GUPTA  <span> Maths </span></p>
                             </div>
                         </div>
                         </div>
                         <div className="item">
                         <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/SRADDHA-MISHRA.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per"> 93.25 %</p>
                             <p className="name">SRADDHA MISHRA <span> Commerce </span></p>
                             </div>
                         </div>
                         </div>
                         <div className="item">
                         <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/DIPALI.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per">    91.25%   </p>
                             <p className="name">DIPALI   <span> Commerce </span></p>
                             </div>
                         </div>
                         </div>
                         <div className="item">
                         <div className="topperdiv">
                         <div className="topperimages">
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/HARSHITA-CHECHARA.jpg" className="img-fluid"/>
                             </div>
                             <img src="https://webapi.entab.info/api/image/SBSB/public/Images/tooperpicicn.png" className="img-fluid tooperpicicn"/>
                             <div className="topperdesc">
                             <p className="per">  91%</p>
                             <p className="name">HARSHITA CHECHARA        <span> Commerce </span></p>
                             </div>
                         </div>
                         </div>
                */}
            
          
    
          
     </>
  )
}

export default TopperXII